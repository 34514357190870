import i18n from "locales/i18n";

i18n.addResourceBundle("ar", "app", {
  welcome: "اهلاً بكم في يـمّ",
  copyrights: "جميع الحقوق محفوظة يَمّ© {{year}}",
  changeLanguage: "تغيير اللغة",
  loading: "جاري التحميل",
  requiredField: "هذا الحقل مطلوب",
  register: "تسجيل جديد",
  login: "تسجيل دخول",
  goBack: "الرجوع",
  help: "مساعدة",
  home: "الرئيسية",
  about: "من يَمّ؟",
  signUp: "إنشاء حساب",
  logout: "تسجيل خروج",
  sureLogout: "هل انت متأكد انك تريد تسجيل خروج؟",
  profileDetails: "الملف الشخصي",
  myOrders: "طلباتي",
  signUpNow: "قم بتسجيل الدخول الان لتتمكن من الحصول على إرجاع آني",
  suggestions: "بقترح متجر",
  business: "الأعمال",
  receivePromotion: "خلّك قريب واسمع أخر أخبارنا وعروضنا الترويجية",
  registerSubmit: "أرسل",
  email: "البريد الإلكتروني",
  invalidEmail: "البريد الإلكتروني غير صحيح",
  stayUpdated: "ابق على اطلاع",
  submit: "تأكيد",
  next: "التالي",
  back: "السابق",
  helpCenter: "بوابة الدعم",
  privacyPolicy: "سياسة الخصوصية",
  terms: "الشروط والأحكام",
  consumers: "العملاء",
  yamm: "يَمّ",
  enterPhoneNumber: "ادخل رقم الجوال ",
  phoneNumber: "رقم الجوال",
  dialCode: "رمز الدولة",
  rememberMe: "تذكرني",
  nationalId: "الهوية الوطنية",
  dateOfBirth: "تاريخ الميلاد",
  dontHaveAccount: "ليس لديك حساب لدينا ؟",
  otpSentToYou:
    "أرسلنا رمز تحقق علي رقم جوالك {{number}} ، اطلع عليه واكتبه في الخانات التالية:",
  verification: "تأكد",
  expiredOTP: "انتهت صلاحية رمز التحقق",
  loginSuccess: "تم تسجيل الدخول بنجاح",
  loginFailed: "لم تنجح عملية تسجيل الدخول",
  successBody: "الآن تقدر تسترد قيمة مشترياتك في ثواني معدودة",
  failedBody:
    "هناك خطأ في بعض البيانات المدخلة، من فضلك تأكد من صحة البيانات المدخلة وحاول مرة اخرى لاحقاً",
  registerSuccess: "مبروك ، حسابك صار جاهز! ",
  registerFailed: "لم تنجح عملية إنشاء الحساب",
  nextStep: "الخطوة التالية",
  personalInfo: "عرّفنا عليك !",
  resendCode: "ماوصلني الرمز ،أرسل مره ثانية!",
  otpError: "رمز التحقق غير صحيح",
  returnToOrders: "العودة لمرتجعاتك",
  searchLocation: "ابحث عن الموقع",
  nationalIdMinLength: "رقم الهوية لا يجب أن يقل عن 10",
  phoneMinLength: "رقم الجوال لا يجب ان يقل عن 9",
  phoneMaxLength: "رقم الجوال لا يجب ان يزيد عن 9",
  goHome: "إلى الرئيسية",
  searchIdOrStoreName: "ابحث باستخدام رقم الطلب او اسم المتجر",
  partiallyRejected: "تم رفض البعض",
  confirmNotificationMessage:
    "نحتاج موافقتك على استقبال اشعارات خاصة تطور حالات طلباتكم",
  startWithZeroPhoneNumber: "لا يجب ان يبدأ رقم الجوال بصفر",
  nationalIdRequired: "الرقم القومي مطلوب",
  loginNow: "مستخدم جديد؟ اكتب رقم جوالك وبنسجلك",
  confirm: "تأكيد",
  accept: "اوافق",
  userAgreement: "اوافق علي اتفاقيه المستخدم ",
  ibanHelper: "مثال: SA99 9999 9999 9999 9999 9999",
  pending: "بإنتظار المراجعة",
  accepted: "تمت الموافقة",
  closed: "مغلق",
  complete: "مكتمل",
  delivered: "تم التوصيل",
  inProgress: "تحت المعالجة",
  rejected: "مرفوض",
  shipped: "تم التسليم",
  waitingToBeShipped: "بإنتظار التسليم",
  refunded: "تم الاسترجاع",
  partiallyRejecte: "مرفوض جزئيا",
  pendingInvoiceCustome: "بإنتظار معالجة فاتورة العميل",
  inReview: "تحت المراجعة",
  rejectedItems: "منتجات مرفوضة",
  submitted: "طلب جديد",
  overdue: "منتهي",
  onTheWay: "جاري الشحن للمتجر",
  paid: "مدفوع",
  send: "سجّلني",
  delete: "حذف",
  yammBusiness: "متجر وودنا ننضم!",
  phoneNumberPlaceholder: "5xxxxxxxx",
  faqs: "الأسئلة الشائعة",
  readMore: "أقرء المزيد",
  badConnection: "هناك اضطراب في توصلك بالانترنت",
  nafathConfirmationNeeded: "التحقق عن طريق نفاذ",
  resendNafathVerification: "إعادة ارسال كود تحقق نفاذ",
  openNafathApplication: "قم بفتح تطبيق نفاذ",
  requireNafath:
    'التسجيل في يمّ يتطلب تحميل تطبيق "نفاذ" التابع لمركز المعلومات الوطني من متجر التطبيقات، وذلك من أجل التحقق الآمن لهويتك الرقمية',
  requireNafathGuild:
    "الرجاء فتح تطبيق نفاذ المرتبط بالهوية {{nationalIdEnd}}******{{nationalIdStart}} وتأكيد الطلب باختيار الرقم التالي، في حالة لم يكن رقم الهوية صحيح",
  enterTheBelowCodeToNafathAuthenticator:
    "الرجاء فتح تطبيق نفاذ المرتبط بالهوية وتأكيد الطلب باختيار الرقم التالي، في حالة لم يكن رقم الهوية صحيح",
  ifNafathVerified: "في حالة لم يتم تحويلك تلقائياً بعد تأكيد نفاذ",
  navigateNafathSuccess: "اضغط هنا",
  phoneNumberRequired: "رقم الجوال مطلوب",
  titleTab: "Yamm - يمّ | استرجع قيمة مشترياتك من المتاجر الإلكترونية",
  metaDescription:
    "نظام استرجاع للمتاجر الإلكترونية ، استرجع قيمة مشترياتك من المتاجر خلال ٣٠ ثانية ",
  verifyNafathManually: "في حالة لم يتم تحويلك تلقائياً ، اضغط هنا",
  clientLoginButton: "للعملاء",
  businessLoginButton: "للأعمال",
  clickHere: "اضغط هنا",
  increaseRevenue: "احسب ارباحك",
  updateNationalId: "تحديث رقم الهوية",
  nationalIdUpdatedSuccessfully: "تم تحديث رقم الهوية بنجاح",
  needToUpdateNationalId: "قم بتحديث رقم الهوية",
  checkIban: "تحقق من الآيبان",
  ifYouAreFacingIssues: "ان كنت تعاني من مشاكل  في التسجيل",
  abortProcess: "اضغط هنا لالغاء عملية التسجيل والمحاولة مرة اخرى",
  whyNafathQuestion: "لماذا نتحقق من هويتك الشخصية بواسطة نفاذ؟",
  whyNafathAnswer:
    "من أجل الالتزام بمكافحة غسيل الأموال و تقليل فرص الاحتيال، سنحتاج إلى التحقق من هويتك بواسطة نفاذ . يجب عليك القيام بذلك مرة واحدة فقط عند الاشتراك. خصوصيتك وأمانك هما محل اهتمامنا حيث نقوم بتخزين معلوماتك بأعلى معايير الأمان.",
  needHelp: "تحتاج مساعدة؟ تواصل معنا",
  contactUs: "تواصل معنا",
  unknownError: "هناك خطأ تقني، الرجا المحاولة في وقت لاحق",
  nafathVerificationMayDelay:
    "الرد من نفاذ قد يتأخر قليلاً ، ارجو الإنتظار في حالة التفعيل.",
  gotStuck: "تواجه تعليق في التفعيل؟",
});
